/* Text-Style */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;500&display=swap');

/* All */
* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    margin: 0;
    background-color: rgb(20, 20, 20);
}

/* Pages */
.home-content {
    padding-left: 10px;
    color: white;
}

.contact-content {
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
}

.profile-content {
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
}

.login-content {
    text-align: center;
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    color: white;
}

#uno {
    font-size: 30px;
}
#doux {
    font-size: 20px;
    padding-top: 10%;
}
#trese {
    font-size: 20px;
    padding-top: 3%;
}
#quatro {
    font-size: 20px;
    padding-top: 3%;
}
#longo {
    font-size: 30px;
    padding-top: 10%;
}

/* Nav-Bar */

.nav {
    font-size: 25px;
    background-color: rgb(5, 5, 4);
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}

.nav ul a:hover {
    background-color: rgb(71, 71, 71);
    border-radius: 5px;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

/* Profile */
.profile-img:hover {
    opacity: 1.0;
}

.profile-name {
    font-size: 20px;
}

/* ProfilePage */
.profile-username {
    color: white;
    margin: 20px;
}

.profile-friends-count {
    color: white;
    margin: 20px;
}

.profile-pfp {
    margin: 20px;
    border-radius: 50%;
}

/* Login-Error */
.error {
    color:red;
}